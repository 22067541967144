import(/* webpackMode: "eager", webpackExports: ["IntercomContainer"] */ "/app/apps/admin/components/intercom.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/apps/admin/components/providers.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Barlow\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--fontBarlow\",\"weight\":[\"600\",\"700\",\"900\"]}],\"variableName\":\"fontBarlow\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Barlow_Condensed\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--fontBarlowCondensed\",\"weight\":[\"500\",\"600\",\"800\"]}],\"variableName\":\"fontBarlowCondensed\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Onest\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--fontOnest\",\"weight\":[\"400\",\"600\"]}],\"variableName\":\"fontOnest\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"styles/fonts/index.ts\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--fontNunito\",\"weight\":[\"400\",\"600\"]}],\"variableName\":\"fontNunito\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/admin/styles/style.css");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/cache/client-cache-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/config/client-config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useLazyRemote"] */ "/app/packages/shared-hooks/src/hooks/fetch/use-lazy-remote.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useRemote"] */ "/app/packages/shared-hooks/src/hooks/fetch/use-remote.ts");
