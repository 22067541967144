'use client';

import { AlertDialogProvider } from '@spikemark/ui/shell/alert/alert-dialog-provider';
import { GoogleAnalytics } from '@spikemark/ui/shell/google-analytics';
import { TooltipProvider } from '@spikemark/ui/components/ui/tooltip';
import { ClientConfigProvider, IClientConfig } from '@spikemark/core';
import { SessionProvider } from 'next-auth/react';
import { FC, PropsWithChildren } from 'react';
import { TrackerScript } from './tracker';
import { SpikemarkUser } from '@spikemark/auth';
import { SchoolTeam } from '@spikemark/rest-api';

export const Providers: FC<
  PropsWithChildren<{
    clientConfig: IClientConfig;
    session?: SpikemarkUser;
    userSchools?: SchoolTeam[];
  }>
> = ({ children, clientConfig, session, userSchools }) => {
  return (
    <SessionProvider>
      <ClientConfigProvider value={clientConfig}>
        <GoogleAnalytics />
        <TrackerScript
          apiKey={clientConfig.mixpanel.apiKey}
          session={session}
          userSchools={userSchools}
        />
        <AlertDialogProvider>
          <TooltipProvider>{children}</TooltipProvider>
        </AlertDialogProvider>
      </ClientConfigProvider>
    </SessionProvider>
  );
};
