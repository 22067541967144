import { DialogProps } from '@radix-ui/react-dialog';
import { FC, useCallback, useState } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
} from '../../components/ui/alert-dialog';
import { Button } from '../../components/ui/button';
import { AlertButtonPayload, AlertPayload } from './types';

type ShellAlertDialogProps = AlertPayload &
  Pick<DialogProps, 'open' | 'onOpenChange'> & {
    onClose: (value: any) => void;
  };

export const ShellAlertDialog: FC<ShellAlertDialogProps> = ({
  title,
  message,
  actionButtons,
  open,
  onClose,
  contentClassName,
}) => {
  return (
    <AlertDialog open={open} onOpenChange={() => onClose(null)}>
      <AlertDialogContent className={contentClassName}>
        <AlertDialogHeader>
          {title && <AlertDialogTitle>{title}</AlertDialogTitle>}
        </AlertDialogHeader>
        <AlertDialogDescription>{message}</AlertDialogDescription>
        <AlertDialogFooter className={actionButtons ? 'sm:justify-end' : ''}>
          {actionButtons ? (
            <>
              <Button onClick={() => onClose(null)} type="button" variant="ghost">
                Cancel
              </Button>
              {actionButtons?.map((button) => (
                <ShellAlertDialogActionButton
                  key={button.label}
                  button={button}
                  onClose={onClose}
                />
              ))}
            </>
          ) : (
            <Button variant="primary" onClick={() => onClose(true)}>
              OK
            </Button>
          )}
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

function ShellAlertDialogActionButton<T>({
  button,
  onClose,
}: {
  button: AlertButtonPayload<T>;
  onClose: (value?: T) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const handleClick = useCallback(async () => {
    if (typeof button.value === 'function') {
      setIsLoading(true);
      const resolvedValue: T = await (button.value as Function)();
      setIsLoading(false);
      onClose(resolvedValue);
    } else {
      onClose(button.value);
    }
  }, [button, onClose]);

  return (
    <Button
      key={button.label}
      {...button.props}
      loading={isLoading}
      className={button.props?.className}
      onClick={handleClick}
      type="button"
    >
      {button.label}
    </Button>
  );
}
