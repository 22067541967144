'use client';
import React, { useEffect } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import { useSession } from 'next-auth/react';

export const IntercomContainer = ({ enabled }: { enabled?: boolean }) => {
  const { data: session } = useSession();
  const user = session?.user;
  useEffect(() => {
    if (user && enabled) {
      Intercom({
        app_id: 'mk4jlzvs',
        user_id: user.id,
        name: user.name ?? '',
        email: user.email,
        custom_launcher_selector: '#intercom-launcher',
      });
    }
  }, [user, enabled]);

  return <></>;
};
