import { TRACKED_PROPERTIES } from '@/constants';
import { SpikemarkUser } from '@spikemark/auth';
import { SchoolTeam } from '@spikemark/rest-api';
import pick from 'lodash/pick';
import mixpanel from 'mixpanel-browser';
import Script from 'next/script';
import { useEffect } from 'react';

export const TrackerScript = ({
  apiKey,
  session,
  userSchools,
}: {
  apiKey?: string;
  session?: SpikemarkUser;
  userSchools?: SchoolTeam[];
}) => {
  const trackingEnabled = apiKey !== 'MixPanelApiKey';

  useEffect(() => {
    if (trackingEnabled) {
      mixpanel.identify(session?.userId ?? 'anonymous');
      mixpanel.people.set({
        [TRACKED_PROPERTIES.user.id]: session?.userId,
        [TRACKED_PROPERTIES.user.name]: session?.name,
        [TRACKED_PROPERTIES.user.roles]: session?.roles,
        [TRACKED_PROPERTIES.user.schools]: userSchools?.map((school) =>
          pick(school, ['schoolId', 'schoolName', 'boardName', 'gender', 'division', 'conference'])
        ),
      });
    }
  }, [apiKey, session, trackingEnabled, userSchools]);

  return (
    <Script id="tracker-script">
      {mixpanel.init(trackingEnabled ? apiKey! : ' ', { track_pageview: true })}
    </Script>
  );
};

export { mixpanel as tracker };
